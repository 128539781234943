
import Swiper from 'swiper';
import { Navigation, Scrollbar, Pagination } from 'swiper/modules';
import { tabHandler } from './helpers';

const banner__texts = document.querySelector('.banner__texts');

tabHandler();

new Swiper('.banner-swiper-wrapper', {
  modules: [Pagination],
  on: {
    slideChange() {
      if (banner__texts) {
        banner__texts.classList.remove('banner__texts--active')

        if (!banner__texts.classList.contains('banner__texts--active')) {
          window.addEventListener('scroll', () => {
            banner__texts.classList.add('banner__texts--active')
          }, { once: true })
        }
      }
    },
  },
  pagination: {
    el: '.banner__colors',
    bulletClass: 'banner__color',
    bulletActiveClass: 'banner__color--active',
    bulletElement: 'button',
    clickable: true,
  },
});

new Swiper('.swiper-design', {
  modules: [Navigation, Scrollbar],
  spaceBetween: 5,
  navigation: {
    nextEl: '.swiper-design-button-next',
    prevEl: '.swiper-design-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    snapOnRelease: true,
    dragSize: 'auto',
  },
});