export function tabHandler() {
  const tabs = document.querySelectorAll('[data-role="tab"]')
  const tabBodies = document.querySelectorAll('[data-role="tab-body"]')

  tabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      tabs.forEach((e) => {
        if (e.dataset.type === tab.dataset.type && e.classList.contains('tab--active')) {
          e.classList.remove('tab--active')
        }
      })
      tab.classList.add('tab--active')
      tabBodies.forEach((tabBody) => {
        if (tabBody.dataset.type === tab.dataset.type) {
          if (tabBody.classList.contains('tab-body--active')) {
            tabBody.classList.remove('tab-body--active')
          }
          if (tabBody.dataset.index === tab.dataset.index) {
            tabBody.classList.add('tab-body--active')
          }
        }
      })
    })
  })
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}